import { Component, Input, ViewChild } from '@angular/core';
import { TypewritingParagraphComponent } from 'src/components/typewriting-paragraph/typewriting-paragraph.component';

@Component({
  selector: 'app-candidate-analysis',
  templateUrl: './candidate-analysis.component.html',
  styleUrls: ['./candidate-analysis.component.scss']
})
export class CandidateAnalysisComponent {

  @Input()
  candidate: any;

  @ViewChild('faqAnswer')
  faqAnswerText: TypewritingParagraphComponent;

  showAnswerText = false;

  constructor() { }

  ngOnInit(): void {
  }

  showAnswer(faqItem) {
    if (!this.showAnswerText) {
      this.showAnswerText = true;
      setTimeout(() => { this.typeAnswer(faqItem) }, 1500);
    }
    else {
      this.typeAnswer(faqItem);
    }
  }

  typeAnswer(faqItem) {
    var answer = faqItem.Answer;
    this.faqAnswerText.text = answer;
    this.faqAnswerText.showText();
  }

}
