import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from './environment.prod';

import Hotjar from '@hotjar/browser'



if (environment.production) {
  enableProdMode();

  // const script = document.createElement('script');
  // script.type = 'text/javascript';
  // script.src = 'https://cdn.zipy.ai/sdk/v1.0/zipy.min.umd.js';
  // script.crossOrigin = "anonymous";
  // script.onload = (e: any) => {
  //   var w : any = window;
  //   w.zipy && w.zipy.init('50e7ad1f');
  // };

  // document.body.appendChild(script);

  const siteId = 5278098;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  // Initializing with `debug` option:
  Hotjar.init(siteId, hotjarVersion, {
    debug: true
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
