import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScuiModalComponent } from '@sc-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent implements OnInit, OnDestroy {  

  @ViewChild('notificationsModal')
  notificationsModal : ScuiModalComponent;

  modalRef : NgbModalRef = null;

  _destroyed = new Subject<any>();
  
  constructor(public notifications : NotificationsService, private router : Router, private modals : NgbModal) { }

  ngOnInit(): void {
    this.notifications.onModalShow.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this.notificationsModal.show().subscribe();
    });

    this.notifications.onModalClose.pipe(takeUntil(this._destroyed)).subscribe(() => {
      if (!this.modalRef) return;
      this.notificationsModal.dismiss();
    });
  }

  ngOnDestroy()
  {
    this._destroyed.next();
  }

  execute(i)
  {
    this.notifications.execute(i);
    if (!this.modalRef) return;
      this.modalRef.close();
  }

  navigateToProject(p)
  {
    this.router.navigateByUrl('/projects/' + p.Id);
    if (!this.modalRef) return;
      this.modalRef.close();
  }

}
