<sc-modal #notificationsModal>

  <sc-modal-header>
    <div class="row align-items-center">
      <div class="col-auto me-n2">
        <span class="icon">
          <i class="fa-sharp fa-solid fa-bell"></i>
        </span>
      </div>
      <div class="col">
        <h5 class="modal-title mb-0">Aufgaben</h5>
      </div>
    </div>
   </sc-modal-header>

  <sc-modal-body>

    <p class="text-muted mb-0" *ngIf="notifications.projects.length == 0">
      Sie haben keine offenen Aufgaben
    </p>

    <section class="notification-group" *ngFor="let project of notifications.projects">

      <a (click)="navigateToProject(project)">
        <h4 class="mb-4">{{ project.Name }} <span>m|w|d</span></h4>
      </a>

      <ul class="list-unstyled">

        <li *ngFor="let item of project.Items" [ngSwitch]="item.Content" (click)="execute(item);">
          <app-notifications-modal-item *ngSwitchCase="'#candidatedecide'">
            <sc-avatar class="me-2" size="xs" [user]="item.Candidate"></sc-avatar><strong>{{ item.Candidate.FirstName }} {{ item.Candidate.LastName }}</strong> wartet auf Rückmeldung
          </app-notifications-modal-item>
          <app-notifications-modal-item *ngSwitchCase="'#candidatefeedback'">
            Ihre Einschätzung zu {{ item.Candidate.FirstName }} {{ item.Candidate.LastName }}
          </app-notifications-modal-item>
          <app-notifications-modal-item *ngSwitchCase="'#candidatestatus'">
            Wie ist der Status von {{ item.Candidate.FirstName }} {{ item.Candidate.LastName }}
          </app-notifications-modal-item>
          <app-notifications-modal-item *ngSwitchCase="'#projectreviewjobsite'">
            Bitte geben Sie die Jobsite frei
          </app-notifications-modal-item>
          <app-notifications-modal-item *ngSwitchCase="'#projectfeedbackjobsite'">
            Bitte geben Sie Feedback zur Jobsite
          </app-notifications-modal-item>
        </li>

      </ul>

    </section>
  </sc-modal-body>
</sc-modal>