<ng-template [appTopbarTemplate]>
  <div class="row align-items-center no-gutters" *ngIf="candidate">
    <div class="col-auto">
      <sc-avatar class="me-3" size="xs" [user]="candidate"></sc-avatar>
    </div>
    <div class="col d-none d-md-block">
      <h4 class="h5 mb-0">{{ candidate.FirstName }} {{ candidate.LastName }}</h4>
    </div>
    <div class="col-auto mt-md-1">
      <app-nav-tabs></app-nav-tabs>
    </div>
  </div>
</ng-template>

<div class="scroll-dummy" *ngIf="!candidate"></div>

<div class="content" [@fadeIn]="state">

  <div class="container" *ngIf="candidate">
    <div class="row">
      <div class="col-12">
        <app-notification-candidate [candidate]="candidate" [project]="project" [items]="notifications"></app-notification-candidate>
      </div>
    </div>
  </div>
 
  <div class="container-xl" *ngIf="candidate">
    <div class="row"> 
      <div class="col-12"> 
        <div class="card card-xl candidate-content">
          <div class="card-body">
            <app-candidate-base [candidate]="candidate" [project]="project"></app-candidate-base>
            <app-candidate-skills [candidate]="candidate"></app-candidate-skills>
            <app-candidate-experience [candidate]="candidate"></app-candidate-experience>
            <app-candidate-education [candidate]="candidate"></app-candidate-education>
            <app-candidate-analysis [candidate]="candidate"></app-candidate-analysis>
            <app-candidate-feedback [candidate]="candidate" [project]="project"></app-candidate-feedback>
          </div>
        </div> 
      </div>

      <div class="col-12 mt-5"> 
        <div class="card card-xl candidate-content">
          <div class="card-body">
            <app-candidate-activities [candidate]="candidate" [project]="project" [pool]="pool"></app-candidate-activities>
          </div>
        </div>
 
      </div>

    </div>
  </div>
  
</div>

<!-- <app-footer-blob></app-footer-blob>-->
