<div class="row" [scAnchorTarget]="'Analyse'" *ngIf="candidate.Assignments[0]?.Data?.Faq?.length">
	<div class="col-12 section-title">
	  <h3 class="mb-5">Analyse</h3>
	</div>
	<div class="col-12">
  
	  <section>

		<div class="row">
			<div class="col-auto" *ngFor="let faqItem of candidate.Assignments[0].Data.Faq">
				<button class="btn btn-secondary btn-round" (click)="showAnswer(faqItem)">{{ faqItem.Question }}</button>
			</div>
		</div>

		<div class="row" *ngIf="showAnswerText">
			<div class="col-12 col-lg-8 mt-4">
				<div class="answer-container">
					<app-typewriting-paragraph #faqAnswer></app-typewriting-paragraph>
				</div>
				
			</div>
		</div>
		
	  </section>
  
	</div>
  </div>
  