import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { TopnavigationComponent } from './layouts/topnavigation/topnavigation.component';
import { AgmCoreModule } from '@agm/core';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { ScUiModule, ApiConfig } from '@sc-ui';
import { ScLogoComponent } from './components/sc-logo/sc-logo.component';
import { MentionModule } from 'angular-mentions';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { TextToHTMLPipe } from './pipes/text-to-html.pipe';

import localeDE from '@angular/common/locales/de';
registerLocaleData(localeDE);

import { SafePipe } from './pipes/safe.pipe';
import { LocalizePipe } from './pipes/localize.pipe';
import { environment } from './environment.prod';
import { ScChatbotModule } from '@sc-ui-chatbot';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CandidateBaseComponent } from './views/candidate/candidate-base/candidate-base.component';
import { CandidateEducationComponent } from './views/candidate/candidate-education/candidate-education.component';
import { CandidateExperienceComponent } from './views/candidate/candidate-experience/candidate-experience.component';
import { ProjectBaseComponent } from './views/project/project-base/project-base.component';
import { ProjectComponent } from './views/project/project.component';
import { CandidateComponent } from './views/candidate/candidate.component';
import { ProjectCandidatesComponent } from './views/project/project-candidates/project-candidates.component';
import { ForgotPwComponent } from './views/forgot-pw/forgot-pw.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { VerticallyCenteredComponent } from './layouts/vertically-centered/vertically-centered.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { SettingsComponent } from './views/settings/settings.component';

import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { CandidateCardComponent } from './components/candidate-card/candidate-card.component';
import { ProjectBriefingComponent } from './views/project/project-briefing/project-briefing.component';
import { CandidateSkillsComponent } from './views/candidate/candidate-skills/candidate-skills.component';

import { InlineSVGModule } from 'ng-inline-svg';
import { MorphingBlobsComponent } from './components/morphing-blobs/morphing-blobs.component';
import { ParticlesComponent } from './components/particles/particles.component';
import { CandidateStatusBadgeComponent } from './components/candidate-status-badge/candidate-status-badge.component';
import { CandidateFeedbackComponent } from './views/candidate/candidate-feedback/candidate-feedback.component';
import { RouterModule } from '@angular/router';
import { NotificationsModalComponent } from './components/notifications-modal/notifications-modal.component';
import { RegisterComponent } from './views/register/register.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationProjectComponent } from './components/notification-project/notification-project.component';
import { NotificationCandidateComponent } from './components/notification-candidate/notification-candidate.component';
import { NotificationsModalItemComponent } from './components/notifications-modal-item/notifications-modal-item.component';
import { FooterBlobComponent } from './components/footer-blob/footer-blob.component';
import { AdminManagerAvatarsComponent } from './components/admin-manager-avatars/admin-manager-avatars.component';
import { EmptyComponent } from './layouts/empty/empty.component';

import { ActivityItemCandidateCommentedComponent } from './components/activity-item/activity-item-candidate-commented/activity-item-candidate-commented.component';
import { ActivityItemCandidateShortlistedComponent } from './components/activity-item/activity-item-candidate-shortlisted/activity-item-candidate-shortlisted.component';
import { ActivityItemCandidateAcceptedComponent } from './components/activity-item/activity-item-candidate-accepted/activity-item-candidate-accepted.component';
import { ActivityItemCandidateRejectedComponent } from './components/activity-item/activity-item-candidate-rejected/activity-item-candidate-rejected.component';
import { ActivityItemCandidateHiredComponent } from './components/activity-item/activity-item-candidate-hired/activity-item-candidate-hired.component';
import { ActivityItemCandidateFeedbackPostedComponent } from './components/activity-item/activity-item-candidate-feedback-posted/activity-item-candidate-feedback-posted.component';
import { ActivityItemProjectJobsiteReadyComponent } from './components/activity-item/activity-item-project-jobsite-ready/activity-item-project-jobsite-ready.component';
import { ActivityItemProjectJobsiteCommentedComponent } from './components/activity-item/activity-item-project-jobsite-commented/activity-item-project-jobsite-commented.component';
import { ActivityItemProjectJobsiteWorkItemCommentedComponent } from './components/activity-item/activity-item-project-jobsite-work-item-commented/activity-item-project-jobsite-work-item-commented.component';
import { ActivityItemProjectJobsiteConfirmedComponent } from './components/activity-item/activity-item-project-jobsite-confirmed/activity-item-project-jobsite-confirmed.component';
import { ActivityItemProjectMessagingDoneComponent } from './components/activity-item/activity-item-project-messaging-done/activity-item-project-messaging-done.component';
import { ActivityItemProjectBriefingCompletedComponent } from './components/activity-item/activity-item-project-briefing-completed/activity-item-project-briefing-completed.component';
import { ActivityItemComponent } from './components/activity-item/activity-item.component';
import { PoolsComponent } from './views/pools/pools.component';
import { PoolComponent } from './views/pool/pool.component';
import { PoolBaseComponent } from './views/pool/pool-base/pool-base.component';
import { PoolCandidatesComponent } from './views/pool/pool-candidates/pool-candidates.component';
import { PoolGroupsComponent } from './views/pool/pool-groups/pool-groups.component';
import { CandidateActivitiesComponent } from './views/candidate/candidate-activities/candidate-activities.component';
import { ProjectActivitiesComponent } from './views/project/project-activities/project-activities.component';
import { CandidateChatsComponent } from './components/candidate-chats/candidate-chats.component';
import { CandidateBaseReminderComponent } from './views/candidate/candidate-base/candidate-base-reminder/candidate-base-reminder.component';
import { CandidateBaseReminderModalComponent } from './views/candidate/candidate-base/candidate-base-reminder-modal/candidate-base-reminder-modal.component';
import { CandidateBaseProjectItemComponent } from './views/candidate/candidate-base/candidate-base-project-item/candidate-base-project-item.component';
import { PoolActivitiesComponent } from './views/pool/pool-activities/pool-activities.component';
import { PoolGroupComponent } from './views/pool-group/pool-group.component';
import { PoolGroupBaseComponent } from './views/pool-group/pool-group-base/pool-group-base.component';
import { PoolGroupCandidatesComponent } from './views/pool-group/pool-group-candidates/pool-group-candidates.component';
import { PoolGroupCandidatesAddModalComponent } from './views/pool-group/pool-group-candidates/pool-group-candidates-add-modal/pool-group-candidates-add-modal.component';
import { ProjectStatusBadgeComponent } from './components/project-status-badge/project-status-badge.component';
import { CandidatePoolStatusBadgeComponent } from './components/candidate-pool-status-badge/candidate-pool-status-badge.component';
import { CandidateBasePoolItemComponent } from './views/candidate/candidate-base/candidate-base-pool-item/candidate-base-pool-item.component';
import { ProjectBaseUserModalComponent } from './views/project/project-base/project-base-user-modal/project-base-user-modal.component';
import { PoolBaseUserModalComponent } from './views/pool/pool-base/pool-base-user-modal/pool-base-user-modal.component';
import { ActivityItemCandidateAddedToPoolComponent } from './components/activity-item/activity-item-candidate-added-to-pool/activity-item-candidate-added-to-pool.component';
import { RegisterForbiddenComponent } from './views/register-forbidden/register-forbidden.component';
import { RegisterSendLinkComponent } from './views/register-send-link/register-send-link.component';
import { ModalBriefingComponent } from './components/modal-briefing/modal-briefing.component';
import { DashboardAnnouncementComponent } from './views/dashboard/dashboard-announcement/dashboard-announcement.component';
import { TypewritingParagraphComponent } from './components/typewriting-paragraph/typewriting-paragraph.component';
import { JobsiteComponent } from './views/jobsite/jobsite.component';
import { JobsiteTopbarComponent } from './views/jobsite/jobsite-topbar/jobsite-topbar.component';
import { JobsiteHeaderComponent } from './views/jobsite/jobsite-header/jobsite-header.component';
import { JobsiteNavComponent } from './views/jobsite/jobsite-nav/jobsite-nav.component';
import { JobsiteSalaryChartComponent } from './views/jobsite/jobsite-salary-chart/jobsite-salary-chart.component';
import { UlRevealComponent } from './components/ul-reveal/ul-reveal.component';
import { JobsiteToolbarComponent } from './views/jobsite/jobsite-toolbar/jobsite-toolbar.component';
import { JobsiteCommentComponent } from './views/jobsite/jobsite-comment/jobsite-comment.component';
import { JobsiteMapComponent } from './views/jobsite/jobsite-map/jobsite-map.component';
import { CandidateAnalysisComponent } from './views/candidate/candidate-analysis/candidate-analysis.component';

@NgModule({
  declarations: [
    AppComponent,
    TopnavigationComponent,
    NotFoundComponent,
    ScLogoComponent,
    Nl2brPipe,
    TextToHTMLPipe,
    SafePipe,
    LocalizePipe,
    TopbarComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    CandidateBaseComponent,
    CandidateEducationComponent,
    CandidateExperienceComponent,
    ProjectBaseComponent,
    ProjectComponent,
    CandidateComponent,
    ProjectCandidatesComponent,
    ForgotPwComponent,
    ImprintComponent,
    PrivacyComponent,
    VerticallyCenteredComponent,
    ProjectsComponent,
    SettingsComponent,
    NavTabsComponent,
    CandidateCardComponent,
    ProjectBriefingComponent,
    CandidateSkillsComponent,
    MorphingBlobsComponent,
    ParticlesComponent,
    CandidateStatusBadgeComponent,
    CandidateFeedbackComponent,
    NotificationsModalComponent,
    RegisterComponent,
    NotificationComponent,
    NotificationProjectComponent,
    NotificationCandidateComponent,
    NotificationsModalItemComponent,
    FooterBlobComponent,
    AdminManagerAvatarsComponent,
    EmptyComponent,
    ActivityItemComponent,
    ActivityItemCandidateCommentedComponent,
    ActivityItemCandidateShortlistedComponent,
    ActivityItemCandidateAcceptedComponent,
    ActivityItemCandidateRejectedComponent,
    ActivityItemCandidateHiredComponent,
    ActivityItemCandidateFeedbackPostedComponent,
    ActivityItemProjectJobsiteReadyComponent,
    ActivityItemProjectJobsiteCommentedComponent,
    ActivityItemProjectJobsiteWorkItemCommentedComponent,
    ActivityItemProjectJobsiteConfirmedComponent,
    ActivityItemProjectMessagingDoneComponent,
    ActivityItemProjectBriefingCompletedComponent,
    PoolsComponent,
    PoolComponent,
    PoolBaseComponent,
    PoolCandidatesComponent,
    PoolGroupsComponent,
    CandidateActivitiesComponent,
    ProjectActivitiesComponent,
    CandidateChatsComponent,
    CandidateBaseReminderComponent,
    CandidateBaseReminderModalComponent,
    CandidateBaseProjectItemComponent,
    PoolActivitiesComponent,
    PoolGroupComponent,
    PoolGroupBaseComponent,
    PoolGroupCandidatesComponent,
    PoolGroupCandidatesAddModalComponent,
    ProjectStatusBadgeComponent,
    CandidatePoolStatusBadgeComponent,
    CandidateBasePoolItemComponent,
    ProjectBaseUserModalComponent,
    PoolBaseUserModalComponent,
    ActivityItemCandidateAddedToPoolComponent,
    RegisterForbiddenComponent,
    RegisterSendLinkComponent,
    ModalBriefingComponent,
    DashboardAnnouncementComponent,
    TypewritingParagraphComponent,
    JobsiteComponent,
    JobsiteTopbarComponent,
    JobsiteHeaderComponent,
    JobsiteNavComponent,
    JobsiteSalaryChartComponent,
    UlRevealComponent,
    JobsiteToolbarComponent,
    JobsiteCommentComponent,
    JobsiteMapComponent,
    CandidateAnalysisComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserTransferStateModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    CommonModule,
    FormsModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCZbirZufBOikmq2n_M1AvtIwGHkqeC-7k', libraries: ["places"] }),
    ScUiModule, 
    BrowserAnimationsModule,
    ScChatbotModule,
    MentionModule,
    InlineSVGModule.forRoot()
  ],
  entryComponents: [],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'de_DE'
    },
    {
      provide: ApiConfig,
      useValue:
      {
        host: environment.api
      }
    },
    {
      provide : NgbModalConfig,
      useValue : {   
        animation: true,     
        centered: true
      }
    }
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
      
  }
}
